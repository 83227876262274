import { h } from 'snabbdom';
import * as licon from 'common/licon';
export function defined(v) {
    return typeof v !== 'undefined';
}
// like mithril prop but with type safety
export function prop(initialValue) {
    let value = initialValue;
    const fun = function (v) {
        if (typeof v !== 'undefined')
            value = v;
        return value;
    };
    return fun;
}
export function bind(eventName, f, redraw = undefined) {
    return {
        insert: (vnode) => {
            vnode.elm.addEventListener(eventName, e => {
                e.stopPropagation();
                f(e);
                if (redraw)
                    redraw();
                return false;
            });
        },
    };
}
export function header(name, close) {
    return h('button.head.text', {
        attrs: { 'data-icon': licon.LessThan, type: 'button' },
        hook: bind('click', close),
    }, name);
}
