import { h } from 'snabbdom';
import * as xhr from 'common/xhr';
import { bind, header } from './util';
export function ctrl(data, trans, dimension, redraw, close) {
    function dimensionData() {
        return data[dimension()];
    }
    return {
        dimension,
        trans,
        data: dimensionData,
        set(t) {
            const d = dimensionData();
            d.current = t;
            applyPiece(t, d.list, dimension() === 'd3');
            const field = `pieceSet${dimension() === 'd3' ? '3d' : ''}`;
            xhr
                .text(`/pref/${field}`, {
                body: xhr.form({ [field]: t }),
                method: 'post',
            })
                .catch(() => lichess.announce({ msg: 'Failed to save piece set  preference' }));
            redraw();
        },
        close: close,
    };
}
export function view(ctrl) {
    const d = ctrl.data();
    return h('div.sub.piece.' + ctrl.dimension(), [
        header(ctrl.trans.noarg('pieceSet'), () => ctrl.close()),
        h('div.list', d.list.map(pieceView(d.current, ctrl.set, ctrl.dimension() == 'd3'))),
    ]);
}
function pieceImage(t, is3d) {
    if (is3d) {
        const preview = t == 'Staunton' ? '-Preview' : '';
        return `images/staunton/piece/${t}/White-Knight${preview}.png`;
    }
    return `piece/${t}/wN.svg`;
}
function pieceView(current, set, is3d) {
    return (t) => h('button.no-square', {
        attrs: { title: t, type: 'button' },
        hook: bind('click', () => set(t)),
        class: { active: current === t },
    }, [
        h('piece', {
            attrs: { style: `background-image:url(${lichess.assetUrl(pieceImage(t, is3d))})` },
        }),
    ]);
}
function applyPiece(t, list, is3d) {
    if (is3d) {
        $('body').removeClass(list.join(' ')).addClass(t);
    }
    else {
        const sprite = document.getElementById('piece-sprite');
        sprite.href = sprite.href.replace(/[\w-]+(\.external|)\.css/, t + '$1.css');
        document.body.dataset.pieceSet = t;
    }
    lichess.pubsub.emit('theme.change');
}
