import { h } from 'snabbdom';
import * as xhr from 'common/xhr';
import { bind, header } from './util';
export function ctrl(data, trans, dimension, redraw, close) {
    function dimensionData() {
        return data[dimension()];
    }
    return {
        dimension,
        trans,
        data: dimensionData,
        set(t) {
            const d = dimensionData();
            d.current = t;
            applyTheme(t, d.list, dimension() === 'd3');
            const field = `theme${dimension() === 'd3' ? '3d' : ''}`;
            xhr
                .text(`/pref/${field}`, {
                body: xhr.form({ [field]: t }),
                method: 'post',
            })
                .catch(() => lichess.announce({ msg: 'Failed to save theme preference' }));
            redraw();
        },
        close,
    };
}
export function view(ctrl) {
    const d = ctrl.data();
    return h('div.sub.theme.' + ctrl.dimension(), [
        header(ctrl.trans.noarg('boardTheme'), () => ctrl.close()),
        h('div.list', d.list.map(themeView(d.current, ctrl.set))),
    ]);
}
function themeView(current, set) {
    return (t) => h('button', {
        hook: bind('click', () => set(t)),
        attrs: { title: t, type: 'button' },
        class: { active: current === t },
    }, [h('span.' + t)]);
}
function applyTheme(t, list, is3d) {
    $('body').removeClass(list.join(' ')).addClass(t);
    if (!is3d)
        document.body.dataset.boardTheme = t;
    lichess.pubsub.emit('theme.change');
}
