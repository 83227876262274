import { h } from 'snabbdom';
import { defined } from './util';
export class PingCtrl {
    constructor(trans, redraw) {
        this.trans = trans;
        this.redraw = redraw;
        this.onLag = (lag) => {
            this.ping = Math.round(lag);
            this.redraw();
        };
        this.onMlat = (lat) => {
            this.server = lat;
            this.redraw();
        };
        this.connect = () => {
            lichess.pubsub.emit('socket.send', 'moveLat', true);
            lichess.pubsub.on('socket.lag', this.onLag);
            lichess.pubsub.on('socket.in.mlat', this.onMlat);
        };
        this.disconnect = () => {
            lichess.pubsub.off('socket.lag', this.onLag);
            lichess.pubsub.off('socket.in.mlat', this.onMlat);
        };
        lichess.pubsub.on('dasher.toggle', v => (v ? this.connect() : this.disconnect()));
    }
}
function signalBars(ctrl) {
    const lagRating = !ctrl.ping ? 0 : ctrl.ping < 150 ? 4 : ctrl.ping < 300 ? 3 : ctrl.ping < 500 ? 2 : 1;
    const bars = [];
    for (let i = 1; i <= 4; i++)
        bars.push(h(i <= lagRating ? 'i' : 'i.off'));
    return h('signal.q' + lagRating, bars);
}
const showMillis = (name, m) => [
    h('em', name),
    h('strong', defined(m) ? m : '?'),
    h('em', 'ms'),
];
export const view = (ctrl) => h('a.status', {
    attrs: { href: '/lag' },
    hook: { insert: ctrl.connect, destroy: ctrl.disconnect },
}, [
    signalBars(ctrl),
    h('span.ping', {
        attrs: { title: 'PING: ' + ctrl.trans.noarg('networkLagBetweenYouAndLichess') },
    }, showMillis('PING', ctrl.ping)),
    h('span.server', {
        attrs: { title: 'SERVER: ' + ctrl.trans.noarg('timeToProcessAMoveOnLichessServer') },
    }, showMillis('SERVER', ctrl.server)),
]);
