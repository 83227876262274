import { h } from 'snabbdom';
import * as licon from 'common/licon';
import { header } from './util';
export function ctrl(data, trans, close) {
    const accepted = new Set(data.accepted);
    return {
        list() {
            return [...data.list.filter(lang => accepted.has(lang[0])), ...data.list];
        },
        current: data.current,
        accepted,
        trans,
        close,
    };
}
export function view(ctrl) {
    return h('div.sub.langs', [
        header(ctrl.trans.noarg('language'), ctrl.close),
        h('form', {
            attrs: { method: 'post', action: '/translation/select' },
        }, ctrl.list().map(langView(ctrl.current, ctrl.accepted))),
        h('a.help.text', {
            attrs: {
                href: 'https://crowdin.com/project/lichess',
                'data-icon': licon.Heart,
            },
        }, 'Help translate Lichess'),
    ]);
}
const langView = (current, accepted) => ([code, name]) => h('button' + (current === code ? '.current' : '') + (accepted.has(code) ? '.accepted' : ''), {
    attrs: {
        type: 'submit',
        name: 'lang',
        value: code,
        title: code,
    },
}, name);
