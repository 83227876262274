import * as licon from 'common/licon';
import * as xhr from 'common/xhr';
import throttle, { throttlePromiseDelay } from 'common/throttle';
import { h } from 'snabbdom';
import { bind, header } from './util';
export function ctrl(raw, trans, redraw, close) {
    const list = raw.map(s => s.split(' '));
    const api = lichess.sound;
    const postSet = throttlePromiseDelay(() => 1000, (soundSet) => xhr
        .text('/pref/soundSet', {
        body: xhr.form({ soundSet }),
        method: 'post',
    })
        .catch(() => lichess.announce({ msg: 'Failed to save sound preference' })));
    return {
        makeList() {
            var _a;
            const canSpeech = (_a = window.speechSynthesis) === null || _a === void 0 ? void 0 : _a.getVoices().length;
            return list.filter(s => s[0] != 'speech' || canSpeech);
        },
        api,
        set(k) {
            api.speech(k == 'speech');
            lichess.pubsub.emit('speech.enabled', api.speech());
            if (api.speech()) {
                api.changeSet('standard');
                postSet('standard');
                api.say('Speech synthesis ready');
            }
            else {
                api.changeSet(k);
                api.play('genericNotify');
                postSet(k);
            }
            redraw();
        },
        volume(v) {
            api.setVolume(v);
            // plays a move sound if speech is off
            api.sayOrPlay('move', 'knight F 7');
        },
        redraw,
        trans,
        close,
    };
}
export function view(ctrl) {
    const current = ctrl.api.speech() ? 'speech' : ctrl.api.theme;
    return h('div.sub.sound.' + current, {
        hook: {
            insert() {
                if (window.speechSynthesis)
                    window.speechSynthesis.onvoiceschanged = ctrl.redraw;
            },
        },
    }, [
        header(ctrl.trans('sound'), ctrl.close),
        h('div.content.force-ltr', [
            h('input', {
                attrs: {
                    type: 'range',
                    min: 0,
                    max: 1,
                    step: 0.01,
                    value: ctrl.api.getVolume(),
                    orient: 'vertical',
                },
                hook: {
                    insert(vnode) {
                        const input = vnode.elm, setVolume = throttle(150, ctrl.volume);
                        $(input).on('input', () => setVolume(parseFloat(input.value)));
                    },
                },
            }),
            h('div.selector', ctrl.makeList().map(soundView(ctrl, current))),
        ]),
    ]);
}
const soundView = (ctrl, current) => (s) => h('button.text', {
    hook: bind('click', () => ctrl.set(s[0])),
    class: { active: current === s[0] },
    attrs: { 'data-icon': licon.Checkmark, type: 'button' },
}, s[1]);
