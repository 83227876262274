import { PingCtrl } from './ping';
import { ctrl as langsCtrl } from './langs';
import { ctrl as soundCtrl } from './sound';
import { ctrl as backgroundCtrl } from './background';
import { ctrl as boardCtrl } from './board';
import { ctrl as themeCtrl } from './theme';
import { ctrl as pieceCtrl } from './piece';
import { prop } from './util';
const defaultMode = 'links';
export function makeCtrl(data, redraw) {
    const trans = lichess.trans(data.i18n);
    const opts = {
        playing: $('body').hasClass('playing'),
        zenable: $('body').hasClass('zenable'),
    };
    const mode = prop(defaultMode);
    const setMode = (m) => {
        mode(m);
        redraw();
    };
    const close = () => setMode(defaultMode);
    const ping = new PingCtrl(trans, redraw);
    const subs = {
        langs: langsCtrl(data.lang, trans, close),
        sound: soundCtrl(data.sound.list, trans, redraw, close),
        background: backgroundCtrl(data.background, trans, redraw, close),
        board: boardCtrl(data.board, trans, redraw, close),
        theme: themeCtrl(data.theme, trans, () => (data.board.is3d ? 'd3' : 'd2'), redraw, close),
        piece: pieceCtrl(data.piece, trans, () => (data.board.is3d ? 'd3' : 'd2'), redraw, close),
    };
    lichess.pubsub.on('top.toggle.user_tag', () => setMode(defaultMode));
    return {
        mode,
        setMode,
        data,
        trans,
        ping,
        subs,
        opts,
    };
}
